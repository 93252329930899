.firstVisibleItem {
    background-color: rgb(22, 130, 93);
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: 22px;
    height: 100%;
    color: rgb(255,255,255);
    display: flex;
    align-items: center;
    font-size: 14px;
}
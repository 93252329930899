.sidebarLeftTitle {
    height: 35px;
    padding-left: 20px;
    display: flex;
    align-items: center;
}

.title {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 400;
    color: rgb(187, 187, 187);
    padding: 0;
    margin: 0;
}


.main-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  margin-bottom: 21px;
}

.activityBar {
  width: 48px;
}

.editor {
  width: 100%;
  overflow-y: auto;
}

.bottom-container {
  height: 22px;
  width: 100%;
  position: fixed;
  bottom: 0;
}

.activityBar{
    background-color: rgb(51, 51, 51);
    display: none;
    flex-direction: column;
    align-items: center;
}

@media (min-width: 768px) {
  .activityBar {
    display: flex;
  }
}

.activityBar-icons {
    display: inline-block;
    margin: 0 auto;
    padding: 0;
    height: 100%;
    width: 100%;
}

.activityBar-icon-link {
    display: flex;
    color: rgba(255,255,255,0.4);
    font-size: 24px;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    overflow: hidden;
    width: 48px;
    height: 48px;
    margin-right: 0;
    box-sizing: border-box;
}

.activityBar-icon-link :hover {
    color: rgba(255,255,255,1);
    cursor: pointer;
}

.active {
    color: rgba(255,255,255,1);
    border-left: solid 2px rgb(255,255,255);
}
pre {
  white-space: normal;
  margin-top: calc(35px + 1em);
  padding-right: 12px;
}

.editor-line {
  display: block;
  color: #9cdcfe;
  font-size: 12px;
  line-height: 18px;
  font-family: Menlo, Monaco, "Courier New", monospace;
  font-weight: normal;
  padding-left: 73px;
  text-indent: -73px;
}

@media (min-width: 768px) {
  .editor-line {
    font-size: 14px;
  }
}


.editorContent {
  counter-reset: line;
  display: block;
  font-family: monospace;
  margin: 1em 0px;
}

.editor-line:before {
  counter-increment: line;
  content: counter(line);
  display: inline-block;
  color: rgb(133, 133, 133);
  width: 22px;
  text-align: right;
  height: 18px;
  font-size: 12px;
  font-weight: 400;
  padding-left: 8px;
  padding-right: 22px;
  vertical-align: middle;
  font-family: Menlo, Monaco, "Courier New", monospace;
}

@media (min-width: 768px) {
  .editor-line:before {
    padding-left: 22px;
  }
}

.cursor-end {
  border-right: 2px solid #979797;
  animation: blink-caret 1s step-end infinite;
}

.link {
  text-decoration: underline;
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: #979797;
  }
}

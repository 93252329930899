.editorTab {
    background-color: rgb(45, 45, 45);
    height: 100%;
    max-width: 120px;
    min-width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 10px;
    cursor: pointer;
    color: rgb(255,255,255);
}

.editorTab-active {
    background-color: rgb(30, 30, 30);
}

.editorTab-title {
    font-size: 13px;
    padding-left: 6px;
}

.editorTab-close {
    color: rgb(255,255,255);
    width: 28px;
    margin-left: auto;
}

.editorTab-close :hover {
    background-color: blue;
}